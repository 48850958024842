
<script>

import Storage from "./../../api/storage";
import RadialProgressBar from 'vue-radial-progress'
export default {
    components: {
      RadialProgressBar
    },
    computed:{
      files(){
        return this.$store.getters["upload/files"];
      },
      parent_id(){
        return this.$store.getters[this.$store.getters["state/state"]+"/parent_id"];
      },
      allDone(){
        return this.$store.getters["upload/files"].findIndex((v)=>v.done == false);
      }
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            hide : false
        };
    },
    methods:{
      getImage(filename){
        var storageUtils = new Storage();
        return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
      },
      showHide(){
        this.hide = !this.hide
      },
      close(){
        this.$store.commit("upload/CLEAR");
        document.getElementById("uploadLoading").style.display = "none";
      },
      remove(id){
        if(this.files.length <= 1){
          this.$store.commit("upload/CLEAR");
          document.getElementById("uploadLoading").style.display = "none";
        }
        this.$store.commit("upload/REMOVE",id);
      },
      getColor(status){
        if(status == 4){
          return "danger";
        }else if(status == 3){
          return "success";
        }else{
          return "primary";
        }
      }
    }
};
</script>
<template>
    <div id="uploadLoading">
      <div class="d-flex justify-content-between align-content-center px-4">
          <p class="mb-4 bold-normal default-text-style color-dark">Uploading {{files.length}} item</p>
          <div class="d-flex">
              <span class="cursor-pointer mr-2" @click="showHide">
              <img src="@/assets/images/icon/chevDown.svg" alt width="100%" />
            </span>
            <span class="cursor-pointer mx-2" @click="close" >
              <img src="@/assets/images/icon/close-secure.svg" alt width="100%" />
            </span>
          </div>
        </div>
        <div v-if="!hide">
          <div class="info-upload-notif">
          <div class="horizontal-modal-line"></div>
            <div class="px-4 d-flex" v-if="allDone != -1">
              <p class="font-size-12 default-text-style"><span class="font-size-12 letter05">Starting upload...</span></p>
            </div>
            <div class="d-flex align-content-center flex-column mb-4 px-4" v-for="file in files" :key="file.id">
              <div class="d-flex justify-content-between align-content-center">
                <div class="d-flex align-items-center justify-items-center file-name-secure">
                  <img  :src="require(`@/assets/images/icon/Folder.svg`)" v-if="file.isFolder" alt height="100%" />
                  <img  :src="require(`@/assets/images/icon/${getImage(file.name)}`)" v-else alt height="100%" />
                  <p class="font-size-16 px-2 mb-0 notif-btm-text">{{file.name}}</p>
                  <p class="font-size-16 px-2 mb-0 text-muted notif-btm-text"  v-if="file.isFolder">{{file.uploaded}} Of {{file.total}}</p>
                </div>
                <div class="d-flex justify-content-end">
                  <div>
                    <radial-progress-bar 
                      :diameter="16"
                      :completed-steps="file.percentage"
                      :total-steps="100"
                      :strokeWidth="3"
                      :innerStrokeWidth="3"
                      startColor="#2098D4"
                      stopColor="#2098D4"
                      innerStrokeColor="#fff"
                      v-show="!file.done && file.status == 2">
                    </radial-progress-bar>

                  </div>
                  <div v-show="!file.done && file.status == 3" class="spinner-border hw-16 font-size-14 text-primary" role="status">
                    <!-- <span class="sr-only">Loading...</span> -->
                  </div>
                  <div v-show="file.done && file.status == 3">
                    <img src="@/assets/images/icon/success-secure.svg" alt=""  data-toggle="tooltip" title="Uploaded successfully">
                  </div>
                  <div v-show="file.status == 4">
                    <img src="@/assets/images/icon/error-secure.svg" alt="" :title="file.error.data ? file.error.data.data : file.error">
                  </div>
                  <div class="cursor-pointer ml-3" @click="remove(file.id)">
                    <img src="@/assets/images/icon/close-secure.svg" alt="">
                  </div>
                </div>
                <div>
              </div>
                  <!-- <div id="progress">
                    <radial-progress-bar 
                      :diameter="30"
                      :completed-steps="file.percentage"
                      :total-steps="100"
                      :strokeWidth="3"
                      :innerStrokeWidth="3"
                      startColor="#2098D4"
                      stopColor="#2098D4"
                      innerStrokeColor="#fff"
                      v-show="!file.done && file.status == 2">
                    </radial-progress-bar>
                    <b-avatar variant="success" size="2rem" v-show="file.done && file.status == 3"><i class="bx bx-check font-size-18"></i></b-avatar>
                    <b-avatar variant="danger" size="2rem" v-show="file.status == 4"><i class="bx bx-info-circle font-size-18"></i></b-avatar>
                  </div>
                  <div class="avatar-xs" @click="remove(file.id)">
                      <span class="avatar-title rounded-circle"><i class="bx bx-x font-size-18"></i></span>
                  </div> -->
                  <!-- <span>{{file.percentage}} %</span> -->
                  <!-- <i class="font-size-18 px-2 bx bx-loader bx-spin" v-if="file.status == 2" ></i> -->
                </div>
              <!-- <b-progress height="6px" class="mr-3 ml-2 mt-0 pt-0 loading-upload" :variant="getColor(file.status)" :value="file.percentage"></b-progress> -->
              <!-- <p class="text-muted ml-2">{{file.error}}</p> -->
            </div>
          </div>
        </div>
    </div>
</template>